import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { getToken } from "../../Store/Action/useFetchData";
import ViewUser from "../Forms/View/ViewUser";
import DeleteUser from "../Forms/Delete/DeleteUser";
import EditUser from "../Forms/Edit/EditUser"
const UserTable = ({ dispatch, res_token }) => {
  const [viewUserModal, setViewUserModel] = useState(false);
  const [viewUserModalId, setViewUserModelId] = useState();
  const [editUserModal, setEditUserModel] = useState(false);
  const [editUserModalId, setEditUserModelId] = useState();
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [deleteUserModalId, setDeleteUserModalId] = useState();
  const [length, setLength] = useState(0);
  const navigate = useNavigate();
  const role = sessionStorage.getItem("role");
  useEffect(() => {
    TableDatatablesManaged.init();
    dispatch(getToken());
  }, [deleteUserModal,editUserModal]);
  const api_token = sessionStorage.getItem("api_token");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showmenu");
      var table1 = $("#showmenu").DataTable({
        columnDefs: [{ orderable: false, targets: 5 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: "/api/all_user_data",
          headers: {
            Authorization: "Bearer " + api_token,
            "X-CSRF-TOKEN": res_token?.data,
          },
          type: "GET",
          // data:
          //   searchData === ""
          //     ? { submit: "" }
          //     : { submit: "custom", status: searchData },
          dataSrc: "user_data",
        },
        columns: [
          {
            data: "id",
            width: "6%",
          },
          {
            data: "name",
            width: "24%",
          },
          {
            data: "email",
            width: "24%",
          },
          {
            data: null,
            width: "24%",
            render: function (data) {
              return `+ ${data.phoneNumber}`;
            },
          },
          {
            data: null,
            width: "24%",
            render: function (data) {
              if (data?.userRole === "0") {
                return "Super Admin";
              } else if (data?.userRole === "1") {
                return "Kitchen User";
              } else if (data?.userRole === "2") {
                return "Sales User";
              } else if (data?.userRole === "3") {
                return "Accounting User";
              }
            },
          },
          // {
          //   data: "service_charge",
          //   width: "30%",
          // },
          {
            data: null,
            width: "18%",
            render: function (data) {
                if (role === "0") {
                return (
                  // '<div class="d-flex line-height"><span  id="viewMenu" value="' + data.id + '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="deleteMenu" value="' +
                  // data.id +
                  // '"><i class="bi bi-trash fs-4 ps-2 me-0 text-danger cursor-pointer"></i> </span></div>'
                  '<div class="d-flex line-height">' +
  '<span id="viewMenu" value="' + data.id + '">' +
    '<i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i>' +
  '</span>' +
  '<span id="editMenu" value="' + data.id + '">' +
    '<i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer"></i>' +
  '</span>' +
  '<span id="deleteMenu" value="' + data.id + '">' +
    '<i class="bi bi-trash fs-4 ps-2 me-0 text-danger cursor-pointer"></i>' +
  '</span>' +
'</div>'
                );
              } else {
                return (
                  '<div class="d-flex line-height"><span  id="viewMenu" value="' +
                  data.id +
                  '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span></div>'
                );
              }
            },
            sorting: false,
          },
         
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showmenu tbody").on("click", "#deleteMenu", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setDeleteUserModal(true);
          setDeleteUserModalId(rowData.id);
        }
      });
      $("#showmenu tbody").on("click", "#viewMenu", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setViewUserModel(true);
          setViewUserModelId(rowData.id);
        }
      });
      $("#showmenu tbody").on("click", "#editMenu", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setEditUserModel(true);
          setEditUserModelId(rowData.id);
        }
      });
    //editMenu
      $("#showmenu_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  return (
    <>
      <style>
        {`


@media only screen and (max-width: 767px) {
  
  .table td:nth-child(1) {
    background-color: #cccccc !important;
    height: 100%;
    top: 0;
    left: 0;
    font-weight: bold;
    ${length === 0 ? `padding-left:9px!important;` : ``}
}
.table td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }
.table td:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }
  .table td:nth-child(4) {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccccc;
    }
    .table td:nth-child(5) {
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #cccccc;
      }

td:nth-of-type(1):before {
  ${length !== 0 ? `content: "#";` : ``}
 
}
td:nth-of-type(2):before {
  content: "Name";
 
}
td:nth-of-type(3):before {
  content: "Email";
}
td:nth-of-type(4):before {
  content: "Phone No.";
}
td:nth-of-type(5):before {
  content: "Role";
}
td:nth-of-type(6):before {
  content: "Action";
}
}
`}
      </style>
      {viewUserModal && (
        <ViewUser
          setViewUserModel={setViewUserModel}
          viewUserModal={viewUserModal}
          viewUserModalId={viewUserModalId}
        />
      )}
      {deleteUserModal && (
        <DeleteUser
          setDeleteUserModal={setDeleteUserModal}
          deleteUserModal={deleteUserModal}
          deleteUserModalId={deleteUserModalId}
        />
      )}
      {editUserModal && (
          <EditUser
          setEditUserModel={setEditUserModel}
          editUserModal={editUserModal}
          editUserModalId={editUserModalId}
           />          
        )
      }
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className="float-right add-role-btn-768  mt-3 mt-lg-5 mt-xl-0">
                <Link to="/add_user">
                  <button
                    type="button"
                    className="btn btn-add-user add-btn mb-auto mb-md-4 mt-3 mt-md-auto"
                  >
                    + ADD USER
                  </button>
                </Link>
              </div>
              <div className="role-table">
                <table
                  id="showmenu"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Name
                      </th>
                      <th className="thead-color" scope="col">
                        Email
                      </th>
                      <th className="thead-color" scope="col">
                        Phone No.
                      </th>
                      <th className="thead-color" scope="col">
                        Role
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
});

export default connect(mapStateToProps)(UserTable);
