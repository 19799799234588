export const initialState = {
  data: {},
  loading: true,
};

export function CrcToken_reducer(state = initialState, action) {
  switch (action.type) {
    case "CRC_TOKEN":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Loggin_reducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Add_user_reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Add_user_role_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_USER_ROLE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Create_menu_reducer(state = initialState, action) {
  switch (action.type) {
    case "CREATE_MENU":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Create_station_reducer(state = initialState, action) {
  switch (action.type) {
    case "CREATE_STATION":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Add_user_name_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_USER_NAME_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Add_sound_light_reducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_SOUND_LIGHT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Food_and_Drinks_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "FOOD_DRINKS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Supplires_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "SUPPLIRES_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Extra_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "EXTRA_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_user_role_reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_ROLE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Send_email_reducer(state = initialState, action) {
  switch (action.type) {
    case "SEND_EMAIL_RESPONSE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Send_otp_reducer(state = initialState, action) {
  switch (action.type) {
    case "SEND_OTP_RESPONSE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Send_password_reducer(state = initialState, action) {
  switch (action.type) {
    case "SEND_PASSWORD_RESPONSE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Calendar_Add_Event_reducer(state = initialState, action) {
  switch (action.type) {
    case "CALENDAR_ADD_RESPONSE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Edit_Calendar_Event_Details_reducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case "EDIT_CALENDAR_EVENT_DETAIL_ON_ID":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Update_user_role_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_USER_ROLE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Getuser_role_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "USER_ROLE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function AddMenu_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_MENU_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Get_menu_type_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_MENU_TYPE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_station_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_STATION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_extra_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_EXTRA_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Update_extra_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_EXTRA_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Delete_extra_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "DELETE_EXTRA_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_suppliers_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_SUPPLIERS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Update_suppliers_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_SUPPLIERS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Delete_suppliers_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "DELETE_SUPPLIERS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Calendar_get_Event_reducer(state = initialState, action) {
  switch (action.type) {
    case "CALENDAR_ALL_EVENTS_RESPONSE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function delete_Calendar_Event_reducer(state = initialState, action) {
  switch (action.type) {
    case "DELETE_EVENT_CAL":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_view_all_menu_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_VIEW_ALL_MENU_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Update_all_menu_reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_ALL_MENU_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Delete_all_menu_reducer(state = initialState, action) {
  switch (action.type) {
    case "DELETE_ALL_MENU_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_view_all_menu_type_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_VIEW_ALL_MENU_TYPE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Update_all_menu_type_reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_ALL_MENU_TYPE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Delete_all_menu_type_reducer(state = initialState, action) {
  switch (action.type) {
    case "DELETE_ALL_MENU_TYPE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_view_station_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_VIEW_STATION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Update_station_reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_STATION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Delete_station_reducer(state = initialState, action) {
  switch (action.type) {
    case "DELETE_STATION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Event_Details__on_id_reducer(state = initialState, action) {
  switch (action.type) {
    case "CALENDAR_EVENT_DETAIL_ON_ID":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Calendar_Update_Event_reducer(state = initialState, action) {
  switch (action.type) {
    case "CALENDAR_UPDATE_RESPONSE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Update_Profile_reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_PROFILE_RESPONSE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Calendar_Filter_reducer(state = initialState, action) {
  switch (action.type) {
    case "CALENDAR_FILTER_RESPONSE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_all_food_and_drinks_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_FOOD_AND_DRINKS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_view_food_and_drinks_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_VIEW_FOOD_AND_DRINKS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Update_food_and_drinks_reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_FOOD_AND_DRINKS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Get_all_sound_and_light_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_SOUND_LIGHT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_all_suppliers_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_SUPPLIERS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_all_extras_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_EXTRAS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_view_sound_and_light_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_VIEW_SOUND_AND_LIGHT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Update_sound_and_light_reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_SOUND_AND_LIGHT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function create_event_type(state = initialState, action) {
  switch (action.type) {
    case "CREATE_EVENT_TYPE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function get_all_menu_data(state = initialState, action) {
  switch (action.type) {
    case "GET_ALL_MENU_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Update_Chnage_Password_reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_CHANGE_PASSWORD":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Get_Notification_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_NOTIFICATION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Edit_or_delete_Notification_data_reducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case "EDIT_OR_DELETE_NOTIFICATION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Main_Kitchen_pdf_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "MAIN_KITCHEN_PDF_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function transfer_event_date_reducer(state = initialState, action) {
  switch (action.type) {
    case "TRANSFER_EVENT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function get_kitchen_event_calendar_reducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case "CALENDAR_KITCHEN_EVENTS_RESPONSE":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function send_setting_reducer(state = initialState, action) {
  switch (action.type) {
    case "SEND_SETTING":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function get_setting_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_SETTING":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function get_Billing_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_BILLING_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Add_Billing_reducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_BILL_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Send_Role_Status_reducer(state = initialState, action) {
  switch (action.type) {
    case "SEND_ROLE_STATUS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Send_Show_Details_reducer(state = initialState, action) {
  switch (action.type) {
    case "SEND_SHOW_DETAILS_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function Get_Edit_Billing_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_EDIT_BILLING_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Edit_Billing_reducer(state = initialState, action) {
  switch (action.type) {
    case "EDIT_BILL_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Admin_reports_PDF_Data_reducer(state = initialState, action) {
  switch (action.type) {
    case "ADMIN_REPORTS_PDF_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Account_reports_PDF_Data_reducer(state = initialState, action) {
  switch (action.type) {
    case "ACCOUNT_REPORTS_PDF_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Read_Notification_Data_reducer(state = initialState, action) {
  switch (action.type) {
    case "READ_NOTIFICATION_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function get_Calendar_Account_Details_reducer(state = initialState, action) {
  switch (action.type) {
    case "CALENDAR_ACCOUNTANT_DETAILS":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function get_User_by_ID_Data_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_USER_BY_ID_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function get_Income_reports_chart_Data_reducer(state = initialState, action) {
  switch (action.type) {
    case "INCOME_REPORTS_CHART_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function get_Discount_reports_chart_Data_reducer(state = initialState, action) {
  switch (action.type) {
    case "DISCOUNT_REPORTS_CHART_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Sales_reporting_PDF_Data_reducer(state = initialState, action) {
  switch (action.type) {
    case "SALES_REPORTING_PDF_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function contract_1_PDF_Data_reducer(state = initialState, action) {
  switch (action.type) {
    case "CONTRACT_1_PDF_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function sub_contract_PDF_Data_reducer(state = initialState, action) {
  switch (action.type) {
    case "SUB_CONTRACT_1_PDF_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function create_event_calendar_reducer(state = initialState, action) {
  switch (action.type) {
    case "CREATE_EVENT_CALENDAR":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function get_event_summary_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_SUMMARY_PDF":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

export function get_invoice_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_INVOICE_PDF":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function get_kitchen_event_summary_PDF_reducer(state = initialState, action) {
  switch (action.type) {
    case "GET_KITCHEN_EVENT_SUMMARY_PDF_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Delete_sound_and_light_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "DELETE_SOUND_AND_LIGHT_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Add_sub_invoice_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_SUB_INVOICE_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Single_account_pay_PDF_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "SINGLE_ACCOUNT_PAY_PDF_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Final_account_pay_PDF_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "FINAL_ACCOUNT_PAY_PDF_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Delete_user_data_reducer(state = initialState, action) {
  switch (action.type) {
    case "DELETE_USER_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}
export function Update_user_role_email_reducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_USER_EMAIL_DATA":
      return { data: action.payload, loading: false };
    default:
      return state;
  }
}

