import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MiniLoader from "../../../MiniLoader";
import { GetUserByIdData, getToken, UpdateUserEmailAndRole } 
from "../../../Store/Action/useFetchData";
import personImage from "../../../../images/image.png";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";


const EditUser = ({
  setEditUserModel,
  editUserModal, 
  editUserModalId,
  dispatch,
  res_get_menu,
  res_update_role_email
}) => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    user_name: "",
    user_password: "",
    email: "",
    user_image: "",
    phone: "",
    role: "",
  });

  // Options for the role select dropdown
  const options = [
    { value: "0", label: "Super Admin" },
    { value: "1", label: "Kitchen User" },
    { value: "2", label: "Sales User" },
    { value: "3", label: "Accounting User" },
  ];

  // Set the initial selected option based on userData.role
  const selectedOption = options.find(option => option.value === userData.role);

  const customStyles = {
    backgroundColor: "#4d4d4d",
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4d4d4d" : "transparent",
      color: state.isSelected ? "white" : "#333",
      ":hover": {
        backgroundColor: "#676767 ",
        color: "white",
        cursor: "pointer",
      },
    }),
  };

  const handleChange = (e) => {
    
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
useEffect(()=>{
console.log(userData,"abcd");

},[userData])

  useEffect(() => {
    dispatch(getToken());
    dispatch(GetUserByIdData(editUserModalId));
  }, [dispatch, editUserModalId]);

  useEffect(() => {
    if (editUserModalId && res_get_menu?.data?.status === true) {
      setUserData({
        user_name: res_get_menu?.data?.data?.name,
        email: res_get_menu?.data?.data?.email,
        user_image: res_get_menu?.data?.data?.profilePicture,
        phone: `+ ${res_get_menu?.data?.data?.phoneNumber}`,
        role: res_get_menu?.data?.data?.userRole,
      });
    }
  }, [editUserModalId, res_get_menu]);

  const handleSubmit = () => {
    
    const submitData = {
      email: userData.email,
      userRole: userData.role,     
    };
    dispatch(UpdateUserEmailAndRole(submitData, editUserModalId, setLoading));
  };

  const handleClose = () => {
    setEditUserModel(false);
    res_get_menu.loading = true;
    res_get_menu.data = {};
  };
  
  useEffect(() => {
    if (res_update_role_email?.data?.status === true) {
      const notify = () =>
        toast.success(res_update_role_email?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        setEditUserModel(false);
        res_update_role_email.loading = true;
        res_update_role_email.data = {};
      }, 1500);
    } else if (res_update_role_email?.data?.status === false) {
      const notify = () =>
        toast.error(res_update_role_email?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_update_role_email.loading = true;
        res_update_role_email.data = {};
      }, 1500);
    }
  }, [res_update_role_email,setEditUserModel]);

  return (
    <>
    <Toaster/>
      <div className={`modal ${editUserModal ? "fade show d-block" : "d-none"}`} tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
          <div className="modal-content">
            <div className="modal-header modal-header-role">
              <h4 className="modal-title text-center fw-bold">Edit User</h4>
              <button type="button" className="btn-close ms-0" onClick={handleClose}></button>
            </div>
            {res_get_menu?.loading ? (
              <MiniLoader />
            ) : (
              <div className="modal-body modal-padd">
                <div className="row mb-3 mt-2">
                  <div className="col-lg-4 col-12">
                    <label className="form-label label">NAME</label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <input
                      className="form-control inpt inpt-768"
                      name="user_name"
                      readOnly
                      value={userData.user_name}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label className="form-label label">EMAIL</label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <input
                      className="form-control inpt inpt-768"
                      name="email"
                      onChange={handleChange}
                      value={userData.email}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label className="form-label label">PHONE</label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <input
                      className="form-control inpt inpt-768"
                      readOnly
                      value={userData.phone}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label className="form-label label">PROFILE PICTURE</label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <img
                      src={userData.user_image ? `http://whitehall.website/api/uploads/profile_image/${userData.user_image}` : personImage}
                      alt="profile_image"
                      className="rounded-3 profile-image"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label className="form-label label">USER ROLES</label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <Select
                      onChange={(e) => setUserData({ ...userData, role: e.value })}
                      options={options}
                      className="inpt inpt-768"
                      placeholder="Roles"
                      value={selectedOption}
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button type="button" className="btn btn-b me-2 mb-4 btn-768" onClick={handleClose}>
                Back
              </button>
              {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SAVE
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                      onClick={handleSubmit}
                    >
                      SAVE
                    </button>
                  )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_menu: state?.get_User_by_ID_Data_reducer,
  res_update_role_email: state?.Update_user_role_email_reducer,
});

export default connect(mapStateToProps)(EditUser);

